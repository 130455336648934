.label-list {
	margin-top: 6px !important;
}
.main-view {
    margin-left: 280px;
}

.well-30 {
    padding: 30px;
    background: #fff;
    width: 100%;
    display: inline-block;
}

.well-20 {
    padding: 20px;
    background: #fff;
    display: inline-block;
    width: 100%;
}

.relative {
    position: relative;
}

.profile-steps {
    background: #fcfbfb;
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    justify-content: space-between;
}

.profile-steps a {
    padding: 10px 14px;
    display: block;
    font-weight: bold;
    color: #abaaaa;
    background: #fcfbfb;
}

@media (max-width: 499px) {
    .profile-steps {
        display: block;
    }
}

@media (max-width: 767px) {
    .profile-steps a {
        padding: 10px 3px;
    }
}

.profile-steps a:hover,
.profile-steps a:active,
.profile-steps a:focus {
    text-decoration: none;
}

.profile-steps a.active {
    color: #23426a;
    background: #fff;
    font-family: 'GothamBlackRegular';

}

select option:checked {
    background-color: #d16565 !important;
}

/* Alt Modal */

.modal-body .bootstrap-select .dropdown-toggle,
.modal-body .bootstrap-select .popover-title {
    display: none;
}

.modal-body .bootstrap-select .dropdown-menu {
    position: static;
    top: auto;;
    left: auto;
    float: none;
    border: 0;
    box-shadow: none;
}

.modal-body .bootstrap-select {
    height: auto;
    border: 0;
    box-shadow: none;
}

.alt-modal .modal-dialog {

    border-radius: 0;
    margin-top: 100px;

}

.alt-modal .modal-content {

    -webkit-box-shadow: none;
    border-radius: 0;
    box-shadow: none;

}

.alt-modal .modal-header {

    position: relative;

}

.alt-modal .close {

    position: absolute;
    top: -40px;
    right: -10px;
    border: 0;
    opacity: 1;

}

.alt-modal .close-alt-modal .nq-sprite {

    width: 40px;
    height: 40px;
    display: block;
    background-position: -284px 9px;

}

.alt-modal .modal-title {

    color: #d16565;

}

.modal-title {
    text-align: center;
}

.select-scrollable {
    max-height: 300px;
    overflow-y: scroll
}

.label-primary.round {

    border-radius: 100%;
    padding: 3px 3px;
    display: inline-block;
    background: #153661;
    font-weight: normal;
    font-size: 10px !important;

}

.well-20 table, .well-30 table {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 700;
}

.well-30 hr {
    margin-top: 15px;
    margin-bottom: 15px;
}

.well-30-yellow {
    background: #f7f9f3 !important;
    padding: 30px;
}

.well-dark {
    background: #3c3c3c;
    color: #fff;
}

.well-dark a {
    color: #fff;
    text-decoration: underline;
}

.well-btn {
    position: absolute;
    right: 15px;
    padding-left: 10px;
    border-radius: 10px 0 0 10px;
}

.well-dark .input-group .form-control {
    border-radius: 0;
}

.btn-square {
    background: #1a4072;
    border-radius: 0 !important;
    color: #fff;
    border: 0;
}

.btn-full-width {
    width: 100%;
}

.td-btn {
    position: relative;
    height: 40px;
}

.td-btn .loading {
    border-top-color: rgba(255, 255, 255, 0.7);
}

.files {
    text-align: center;
    margin-bottom: 10px;
}

.files .nq-files {
    margin: 0 auto;
}

.btn-rounded {
    border-radius: 10px;
    border: 0;
}

.btn-success, .btn-success:hover, .btn-success:active {
    background: #99b022;
}

.main-view .btn-warning {
    background: #e0be03;
}

.main-view .btn-danger {
    background: #c12b2b;
}

.main-view .btn-primary {
    background: #1a4072;
    border-color: #1a4072;
}

.main-view .btn-dark {
    background: #3c3c3c;
    color: #fff;
}

.main-view .btn-light {
    background: #fff;
    color: #3c3c3c;
}

.main-view .btn-pad-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.main-view.open {
    /* handled in js*/
}

.main-view h4 {
    font-size: 24px;
    font-family: 'GothamLightRegular';
}

#search-name {
    font-size: 19px;
}

.display-inline {
    display: inline;
}


.table-striped > tbody > tr > td {
    border-top: 1px solid #f3f3f3;
}

.table-striped > tbody > tr:first-child td {
    border-top: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff;
}

.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #fbfbfb;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    border: 0;
    color: #153661;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'GothamBoldRegular';
    font-size: 11px;
}

.navigation {
    display: block;
    background: #fff;
    max-height: 40px;
}

.navigation .close-sidebar {
    width: 40px;
    height: 40px;
    background: #153661;
    float: left;
    position: relative;
    z-index: 2;
    box-shadow: inset 3px 0px 3px 0px rgba(0, 0, 0, 0.42);
}

.navigation .close-sidebar i.nq-sprite {
    width: 40px;
    height: 40px;
    display: block;
    background-position: -284px 9px;
}

.main-view.open .close-sidebar i.nq-sprite {
    background-position: -314px 8px;
}

.navigation .navbar {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    border: 0;
    max-height: 40px;
}

.navigation .navbar-nav {
    margin: 0;
}

.navigation .navbar li {
    max-height: 40px;
}

.navigation .navbar li a {
    line-height: 14px;
    font-size: 12px;
}

.navigation .navbar .username-item {
    padding: 10px 15px;
    font-size: 13px;
    color: #444;
}

.navigation .navbar .username-item .badge-black {
    padding: 4px;
    background: #444;
    width: 19px;
    height: 19px;
    font-size: 11px;
    position: relative;
    bottom: 1px;
    margin-left: 3px;
}

.navigation .navbar li a .caret {
    margin-top: -3px;
}

.navigation .navbar li a i {
    width: 10px;
    height: 13px;
    display: inline-block;
    background-position: -1px 57px;
}

.navigation .social {
    float: right;
    z-index: 0;
}

.navigation .social .nq-sprite.nq-social {
    width: 44px;
    height: 44px;
    display: inline-block;
}

.navigation .social .nq-sprite.nq-social.nq-facebook {
    background-position: 0px 44px;
}

.navigation .social .nq-sprite.nq-social.nq-twitter {
    background-position: -44px 44px;
}

.navigation .social .nq-sprite.nq-social.nq-linkedin {
    background-position: -88px 44px;
}

.navigation .social .nq-sprite.nq-social.nq-youtube {
    background-position: -132px 44px;
}

.navigation .navbar-nav > li.user-info {
    line-height: 1;
    max-height: auto;
    border-right: 1px solid #eee;
    height: 40px;
}

.navigation .navbar-nav > li.user-info a {
    padding: 13px 15px;
    display: block;
    height: 40px;
}

li.user-info .dropdown-menu li {
    padding: 5px;
}

.search-bar {
    float: right;
    padding: 7px 10px 7px;
    width: 300px;
    float: right;
    position: relative;
    z-index: 2;
}

.search-bar.static {
    padding: 0;
    width: 100%;
    position: relative;
}

.search-bar.static i {
    right: 7px;
    top: 7px;
}

.search-bar i {
    width: 13px;
    height: 13px;
    display: block;
    right: 18px;
    top: 15px;
    position: absolute;
    background-position: -243px -14px;
}

.search-bar .form-control {
    display: block;
    width: 100%;
    height: 26px;
    padding: 6px 7px;
    font-size: 12px;
    border-radius: 3px;
}

.show-count {
    border: 0;
    margin-top: 3px;
}

.navigation .nav-right {
    position: absolute;
    right: 0;
    top: 0;
}

.nq-sidebar {
    width: 280px;
    min-height: 100%;
    overflow: visible;
    position: absolute;
    z-index: 990;
    opacity: 1;
    display: block;
    background: #153661;
    padding-bottom: 280px;
}

.nq-sidebar .branding {
    text-align: center;
}

.nq-sidebar .sidebar-body {
    padding: 30px;
}

.nq-sidebar .sidebar-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.nq-sidebar .sidebar-nav li a {
    width: 90%;
    display: inline-block;
    border-bottom: 0;
    color: #f7f9f3;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 12px;
}

.sidebar-body .sidebar-nav li:nth-of-type(1) a {
    border-bottom: 1px solid #345175;
}

.sidebar-body .sidebar-nav li:nth-last-child(2) a {
    border-top: 1px solid #345175;
}

.nq-sidebar .sidebar-nav li .sidebar-link {
    width: 100%;
    display: inline;

    border-bottom: none;

    color: #8698aa;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 12px;
    font-weight: bold;
}

.nq-sidebar .sidebar-footer {
    padding: 30px;
    position: absolute;
    bottom: 30px;

}

.nq-sidebar .sidebar-footer .sidebar-nav li {
    width: 100%;
    display: inline-block;

    border-bottom: 1px solid #183963;

    color: #8698aa;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    text-align: left;
    position: relative;
    font-family: 'GothamMediumRegular';
}

.nq-sidebar .sidebar-footer .sidebar-nav li:last-child {
    border-bottom: 0;
}

.nq-sidebar .sidebar-footer .sidebar-nav li i.nq-sprite {
    width: 22px;
    position: absolute;
    height: 22px;
    display: block;
    background-position: 0px -179px;
    right: 0;
    top: 7px;
}

.nq-sidebar .sidebar-footer .sidebar-nav li i.nq-phone {
    background-position: -43px -178px
}

.nq-sidebar .sidebar-footer .sidebar-nav li i.nq-pointer {

    background-position: -21px -178px
}

.sidebar-body .sidebar-nav.nav-nq-admin li:nth-of-type(1) a {
    border-bottom: 0;
}

.sidebar-body .sidebar-nav.nav-hirer li:nth-last-child(2) a,
.sidebar-body .sidebar-nav.nav-nq-admin li:nth-last-child(2) a {
    border-top: 0;
}

.sidebar-body .sidebar-nav.nav-hirer li.cv-requests a,
.sidebar-body .sidebar-nav.nav-nq-admin li.cv-requests-pending a,
.sidebar-body .sidebar-nav.nav-nq-admin li.cleared-candidates a,
.sidebar-body .sidebar-nav.nav-nq-admin li.hirer-database a {
    border-bottom: 1px solid #345175;
}

.btn-column {
    max-width: 140px;
    width: 100px;
}

.badged-text {
    position: relative;
}

.badged-text .badge {
    position: absolute;
    right: -22px;
    top: -10px;
}

.nq-sidebar .sidebar-nav li a .badge {
    padding: 4px;
    font-size: 10px;
    font-weight: bold;
}

.nq-sidebar .sidebar-nav li:last-child a {
    border-bottom: 0;
}

.nq-sidebar .sidebar-nav li a:hover {
    text-decoration: none;
}

.nq-pagination a {
    position: relative;
    color: #9d9d9d;
    margin-left: 5px;
}

.nq-pagination a:last-child {

}

.nq-pagination a.active {
    background: #9d9d9d;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    display: inline-block;
    color: #fff;
    padding: 2px 7px;
}

.nq-pagination .previous:after {
    right: 110%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #9d9d9d;
    border-width: 5px;
    margin-top: -5px;
}

.nq-pagination .next:after {
    left: 110%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: #9d9d9d;
    border-width: 5px;
    margin-top: -5px;

}

.p-rel {
    position: relative;
}

.nq-popover {
    position: absolute;
    background: #3c3c3c;
    color: #fff;
    width: 160px;
    padding: 10px;
    border-radius: 3px;
    text-align: center;
    font-size: 12px;
}

.nq-popover:after {
    right: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #3c3c3c;
    border-width: 15px;
    margin-top: 7px;
    transform: rotate(-90deg);
}

tbody.active td,
.table > tbody > tr.active > td,
.table > tbody > tr.active > td td {
    background: #3c3c3c;
    color: #fff;
}

/* Sprite */
.alt-radio {
    display: none;
}

.alt-radio + label span {
    display: inline-block;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    background: url('/img/sprites-v5.png') no-repeat;
    background-position: -73px -235px;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.alt-radio:checked + label span {
    background: url('/img/sprites-v5.png') no-repeat;
    background-position: -52px -235px;
    width: 21px;
    height: 21px;
}

.dropzone-container {
    border: none;
    background: none;
}

.label-list {
    margin: 0;
    padding: 0;
}

.label-header {
    font-size: 12px;
}

.label-list li .label-list {
    margin-top: 6px;
}

.label-list li {
    list-style: none;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 6px;
}

.dropdown-menu.inner li {
    margin: 0;
}

.bootstrap-select.btn-group .dropdown-menu li {
    display: block;
}

.profile-button {
    cursor: pointer;
}

/* Final Queries */
@media (max-width: 992px) {
    .main-view:not(.open) .username-item {
        display: none;
    }
}

@media (max-width: 780px) {

    .nq-sidebar {
        display: none;
    }

    .main-view {
        margin-left: 0;
    }

}

@media (max-width: 767px) {

    .navigation .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .main-view.open .username-item {
        display: none;
    }

    .main-view:not(.open) .navigation .search-bar {
        display: none;
    }

    .navigation {
        height: 40px;
        max-height: 40px;
    }
}

@media (max-width: 499px) {
    .well-30 {
        padding: 15px;
    }

    .navigation .nav-right {
        width: 100%;

    }

    .search-bar {
        width: 100% !important;
    }

    .main-view:not(.open) .social {
        display: none !important;
    }

    .label-list li {
        max-width: 100%;
    }

    .label-danger.label-outline {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .alt-modal .close {
        right: -10px;
    }
}

@media (min-height: 1400px) {
    .nq-sidebar .sidebar-footer {
        bottom: 15%;
    }
}

/* Misc */
.table-striped > tbody > tr.active ul {
    margin-bottom: 30px;
}

.table-striped > tbody > tr ul li {
    margin-top: 10px;
}

/* fix dropdown close button issue in modal */
.alt-modal .popover-title .close {
    position: relative;
    opacity: .2;
    top: 0;
    right: 0;
}

/* disable bootstrap datepicker decades selection */
.bootstrap-datetimepicker-widget .datepicker-years thead,
.bootstrap-datetimepicker-widget .datepicker-years .disabled {
    display: none;
}

.no-margin {
    margin: 0 !important;
}

.dashboard-tip {
    color: #d9534f;
    font-size: 17px;
}

.menu-tip {
    color: #fff;
    font-size: 17px;
}

.input-lg {
    height: 35px;
    padding: 10px 5px;
}

.form-control {
    padding: 6px 5px;
}

.fallback {
    width: 30%;
    margin: auto;
}

.related-seats {
    border: 1px solid #ccc;
    padding: 15px;
}

.related-seats ul {
    list-style: none;
    padding: 0 10px;
    margin: 10px 0 0;
}

.related-seats ul li { 
    cursor: pointer;
    padding-left: 1.3em;
    display: block;
    margin: 0 0 4px 0;
}

.related-seats ul li:before {
    content: "\e074";
    font-family: 'Glyphicons Halflings';
    font-size: 18px;
    float: left;
    margin-top: -3px;
    margin-left: -31px;
    color: #CCCCCC;
    opacity: 0.4;
}

.slider-checkbox {
  position: relative;
}
.slider-checkbox input {
  margin: 0px;
  margin-top: 1px;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: red;
  width: 40px;
  height: 20px;
}
.slider-checkbox input:checked + .label:before {
  background-color: #1877c1;
  content: "";
  padding-left: 6px;
}
.slider-checkbox input:checked + .label:after {
  left: 21px;
}
.slider-checkbox .label {
  position: relative;
  padding-left: 46px;
}
.slider-checkbox .label:before, .slider-checkbox .label:after {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, left 0.3s;
}
.slider-checkbox .label:before {
  content: "";
  color: #fff;
  box-sizing: border-box;
  font-family: 'FontAwesome', sans-serif;
  padding-left: 23px;
  font-size: 12px;
  line-height: 20px;
  background-color: #888;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.slider-checkbox .label:after {
  content: "";
  letter-spacing: 20px;
  background: #fff;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px;
}

.wrap-word {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    white-space: normal;
}
